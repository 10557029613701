
import Auth from "../../services/routes";
import * as moment from "moment";
export default {
  name: "login",
  components: {},
  methods: {
    goTO(url) {
      this.$router.push(url);
    },
    userLogin() {
      let data = {
        email: this.dataLogin.EMAIL,
        senha: this.dataLogin.PASSWORD,
      };

      if (this.dataLogin.EMAIL === "") {
        this.validEmail = true;
      } else {
        this.validEmail = false;
      }
      if (this.dataLogin.PASSWORD === "") {
        this.validPass = true;
      } else {
        this.validPass = false;
      }

      if (this.validPass === false && this.validEmail === false) {
        this.load = true;

        Auth.login(data)
          .then((r) => {
            if (r.data.resultado !== false) {
              this.$notify.success({
                title: "Sucesso",
                message: "Seja Bem Vinda Val! ",
                offset: 120,
                duration: 3000,
              });
              localStorage.setItem("id", r.data.id);
              localStorage.setItem("token", r.data.token);
              this.$router.push("/dashboard");
            } else {
              this.$message({
                showClose: true,
                type: "error",
                message: "Email ou senha inválido",
                offset: 0,
              });
            }
          })
          .finally((e) => {
            this.load = false;
          });
      }
    },
  },
  data() {
    return {
      dataLogin: {
        EMAIL: "",
        PASSWORD: "",
        subscribe: true,
      },
      UserLogin: null,
      load: false,
      alertSuccess: false,
      alertFail: false,
      message: "",
      validEmail: false,
      validPass: false,
      internalClientOn: false,
      internalClientSet: false,
      dataUser: null,
      API: null,
    };
  },
  mounted() {
    Auth.defaultW().then((r) => {
      this.API = r;
    });
  },
};
